.actions {
    text-align: right;
}

/* was .content-wrapper */
.container {
    width: 100%;
    /* width: 960px; */
    /* overflow-x: hidden;*/
    /*overflow-y: auto; */

    /* max-width: 500px;
    margin: 30px auto;
    overflow: auto;
    min-height: 300px;
    border: 1px solid steelblue;
    padding: 30px;
    border-radius: 5px; */
}

.view {
    width: 95%;
    margin: 0 auto;
    /*height: 100%; */
    /* overflow: auto; */
}

.overviewWrapper15 {
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    background-color: white;
    /* box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); */
    padding: 0.5%;
    font-family: "Raleway", "sans-serif";
    border-radius: 0.5rem;
    /* margin-bottom: 0.5rem; */
}

.overviewWrapper14 {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    background-color: white;
    /* box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); */
    padding: 0.5%;
    font-family: "Raleway", "sans-serif";
    border-radius: 0.5rem;
    /* margin-bottom: 0.5rem; */
}

.overviewWrapper2 {
    font-family: "Raleway", "sans-serif";
    display: inline-flex;
    flex-wrap: wrap;
    width: 100%;
    font-size: 18px;
    /*gap: 20px;*/
}

.overviewWrapper2 > * + * {
    margin-left: 20px;
}

.overviewWrapper3 {
    /* display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%; */
    background-color: white;
    /* box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); */
    /* padding: 2%; */
    /* padding-top: 5px; */
    /* padding-top: 5px; */
    /* padding-top: 5px; */
    /* padding-top: 5px; */
    font-family: "Raleway", "sans-serif";

    border-radius: 0.5rem;
}

.resultItem3 {
    width: 90%;

    background: white;
    /* box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); */
    padding: 2rem 2rem 2rem 2rem;

    /* margin-top: 20px; */
    margin: 0 1% 1% 1%;
}

svg {
    background: white;
    /* for stuff such as the axis to show below the graph */
    overflow: visible;
    display: block;
    width: 100%;
}

/* #root {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    height: 100vh;
    padding: 0 50px;
} */

.svgButton {
    width: 100px;
    border: 1px solid black;
    margin: 0 auto 5px;
}
