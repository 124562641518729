@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;800&display=swap);
html {
    --mainColor1: #394855;
    --mainColor2: #9dadbc;
    --mainColor3: #8d99ae;
    --mainColor4: #cfd8dc;
    --mainColor5: #eceff1;
    --mainColor6: #c0c0c0;
    --mainColor7: #696969;
    --mainFontColor: #111111;
    --mainBgColor: white;
    --mainFontFamily1: "Raleway", "sans-serif";
    --mainFontFamily2: "Raleway", "sans-serif";
    --mainModalBgColor: rgb(10, 122, 173);
    --mainModalFontColor: white;
}

/*
Analytics Design Guidelines

    Added by Mikko Karppinen, last edited by Mikko Karppinen on Oct 22, 2021
*/

/*
COLORS
------

PAGE BACKGROUND: #ECEFF1

Graphics Set 1: 0a7aad, 429abd, 5faac5, 7ebacc, 72c3cf, 66ccdd

Set 2: 394855, 9dadbc, 8d99ae, cfd8dc, eceff1, c0c0c0, 696969

Set 3: 8d68b2, ab5ea4, c2558e
*/

body {
    /* font-family: sans-serif; */
    /* margin: 3rem; */
    margin: 0;
    background-color: #eceff1;
}

.set1 {
    color: #0a7aad;
    color: #429abd;
    color: #5faac5;
    color: #7ebacc;
    color: #72c3cf;
    color: #66ccdd;
}

.set2 {
    color: #394855;
    color: #9dadbc;
    color: #8d99ae;
    color: #cfd8dc;
    color: #eceff1;
    color: #c0c0c0;
    color: #696969;
}

.set3 {
    color: #8d68b2;
    color: #ab5ea4;
    color: #c2558e;
}

/*
Fonts and headers
-----------------

Heading fonts:

Raleway

#111111
*/

/* https://fonts.google.com/specimen/Raleway?query=raleway */

body {
    font-family: "Raleway", "sans-serif";
    /* margin: 3rem; */
    margin: 0;
    /* background-color: #eceff1; */
    background-color: #f4f6f8;
}

h1 {
    font-family: "Raleway", "sans-serif";
    font-size: 24px; /* changed 2022-02-21 */
    /* font-style: normal;
    font-variant: normal;
    font-weight: 700;
    line-height: 26.4px; */
    color: #111111;
    margin-bottom: 8px;
}
h2 {
    font-family: "Raleway", "sans-serif";
    font-size: 18px; /* changed 2022-02-21 */
    /* font-style: normal;
    font-variant: normal;
    font-weight: 700;
    line-height: 26.4px; */
    color: #111111;
    margin-bottom: 8px;
    font-weight: 500;
}
h3 {
    font-family: "Raleway", "sans-serif";
    font-size: 14px;
    /*font-style: normal;
    font-variant: normal;
    font-weight: 700;
    line-height: 15.4px; */
    color: #111111;
    margin-bottom: 8px;
}
p {
    font-family: "Raleway", "sans-serif";
    /* font-size: 14px;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 20px; */
    color: #111111;
}
blockquote {
    font-family: "Raleway", "sans-serif";
    /* font-size: 21px;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 30px; */
    color: #111111;
}
pre {
    font-family: "Raleway", "sans-serif";
    /* font-size: 13px;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 18.5667px; */
    color: #111111;
}

/* used in GenericChart for axis fonts */
text {
    font-family: "Raleway", "sans-serif";
    font-size: 10px;
    /*font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 18.5667px; */
    /* color: #111111; */
}

/*
Metrics
-------

Metric font:

Raleway

#111111

Metric name:

Raleway

#394855
*/

.metricFont {
    font-family: "Raleway", "sans-serif";
    color: #111111;
}

.metricName {
    font-family: "Raleway", "sans-serif";
    color: #394855;
}

/*
Selector and dropdowns
----------------------

Raleway

#696969
*/

/*
Paragraph and texts
-------------------

Raleway

#272727
*/

.ValueCard_resultItem__3xXi4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
}

.ValueCard_resultItem__3xXi4:not(:last-of-type) {
    border-right: #394855 1px solid;
}

.ValueCard_resultItemTitle__G-06g {
    font-size: 12px; /* changed 2022-02-21 */
    /*font-weight: 600;*/
    color: #394855;
    margin-bottom: 2%;
}

.ValueCard_amountNumber__3Ulnl {
    font-family: "Raleway", sans-serif;
    font-size: 30px; /* changed 2022-02-21 */
    font-weight: 800;
}

.ValueCard_loader__2wVpS {
    height: 40px; /* changed 2022-02-21 */
}

.GenericChart_Chart_wrapper__3flY6 {
    min-height: 440px;
}

.GenericChart_Chart_loader__1jVpu {
    background-color: white;
    /* width: 100%;
    height: 100%; */
}

/* .Assets_loader div {
    margin: 200px auto;
}

.Assets_loader div svg {
    background: #eceff1;
} */

.Button_btn__3SAfG {
    display: inline-block;
    background: #000;
    color: #fff;
    border: none;
    padding: 10px 20px;
    /* margin: 5px; */
    margin-bottom: 20px;
    margin-left: 10px;
    margin-top: 10px;
    margin-right: 10px;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    font-size: 12px; /* changed 2022-02-21 */
    font-family: inherit;
}

.Button_btn__3SAfG:focus {
    outline: none;
}

.Button_btn__3SAfG:active {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
}

.Button_btnBlock__2qy_e {
    display: block;
    width: 100%;
}

/* From academind: https://www.youtube.com/watch?v=Dorf8i6lCuk */
/* 
.btn {
    font: inherit;
    padding: 0.5rem 1.5rem;
    cursor: pointer;
    border-radius: 4px;
    background-color: #800040;
    color: white;
    border: 1px solid #800040;
    margin: 0 1rem;
}

.btn:hover {
    background-color: #9c1458;
    border-color: #9c1458;
}

.btnAlt {
    background-color: transparent;
    color: #800040;
}

.btnAlt:hover {
    background-color: #f8dae9;
} */

.ModalDateRangePicker_actions__1N1ku {
    text-align: right;
}

.ModalDateRangePicker_modalDateRangePicker__MSMoX {
    /* box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); */
    border-radius: 6px;
    background-color: white;
    padding: 1rem;
    text-align: center;
    width: 40rem;
    left: calc(50% - 20rem);
    height: 28rem;
    z-index: 10;
    position: fixed;
    top: 20vh;
}

.Backdrop_backdrop__3W7jc {
    position: fixed;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.75);
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
}

.MySelect_select__2_qkH {
    /* float: right; */
    padding: 8px 0px 0px 8px;
    /* cursor: pointer; */
    font-size: 14px;
    font-family: inherit;
    text-align: left;
    width: 12rem;

    display: inline-block;
    /* background: #000;
    color: #fff; */
    /* border: none; */
    padding: 9px 18px;
    /* margin: 5px; */
    margin-bottom: 20px;
    margin-left: 10px;
    margin-top: 10px;
    margin-right: 10px;
    border-radius: 3px;
    /* cursor: pointer; */
    /* text-decoration: none; */
    font-size: 12px; /* changed 2022-02-21 */
    font-family: inherit;
}

.SelectedDateRangeHeading_periodName__2z1MF {
    /*font-size: 12px;*/ /* changed 2022-02-21 */
    /*font-weight: 600;*/
    /*color: #394855;*/
    margin-top: 2px; /* changed 2022-02-21 */
    margin-bottom: 2px; /* changed 2022-02-21 */
}

.SelectedDateRangeHeading_dateRange__3DSgZ {
    font-size: 10px;
    margin-top: 2px; /* changed 2022-02-21 */
    margin-bottom: 5px; /* changed 2022-02-21 */
}

.Assets_Assets_loader__2muvs {
    background-color: #eceff1;
    width: 100%;
    height: 100%;
}

.Assets_Assets_loader__2muvs div {
    margin: 200px auto;
}

.Assets_Assets_loader__2muvs div svg {
    background: #eceff1;
}

.Assets_Assets_homeLink__1I79X {
    width: 90%;
    margin: auto;
}

.Assets_Assets_homeLink__1I79X a {
    float: right;
}

.Assets_Assets_modal__3TNXn {
    /* box-shadow: 0 1px 4px rgb(0 0 0 / 20%); */
    border-radius: 6px;
    background-color: white;
    padding: 1rem;
    text-align: center;
    width: 40rem;
    left: calc(50% - 20rem);
    height: 28rem;
    z-index: 10;
    position: fixed;
    top: 20vh;
}

.Assets_Assets_modal_title__2pE94 {
    color: white;
    text-align: center;
}

.Assets_Assets_modal__3TNXn > h2 {
    padding: 20px;
    margin: 0;
    /* border-bottom: 1px solid #555; */
}

.Assets_Assets_overlay__2QSuH {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 100;
}

.Assets_container_2videos__VvKOh {
    color: var(--mainFontColor);
    width: 70%;
    margin: 0 auto;
    overflow: hidden;
    font-family: var(--mainFontFamily2);
}

.Assets_Assets_dashboard_title__1cQ3I {
    width: 100%;
}

.Assets_Assets_main__xNs6e {
    margin: 30px auto;
}

.Assets_Assets_main__xNs6e h2 {
    color: var(--mainFontColor);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    margin-bottom: 0;
    font-size: 1.5rem;
}

.Assets_Assets_main_info__gQG-F {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 30px;
    grid-gap: 10px;
    gap: 10px;
    position: relative;
    width: 100%;
}

.Assets_Assets_main_info__gQG-F button {
    padding: 10px 15px !important;
}

.Assets_Assets_main_info__gQG-F a {
    height: 100%;
    border: none;
    color: var(--mainFontColor);
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    position: absolute;
    right: 10%;
}

.Assets_Assets_main_info__gQG-F a:hover {
    cursor: pointer;
    color: var(--mainFontColor);
    transition: all ease 0.4s;
}

.Assets_Assets_main_info__gQG-F h2 {
    display: inline-block;
    white-space: nowrap;
}

.Assets_Assets_main_info__gQG-F h2,
.Assets_Assets_main_info__gQG-F button {
    padding: 10px 30px;
}

.Assets_Assets_main__xNs6e h2::after {
    display: none;
}

.Assets_container_3videos__16gMg {
    color: var(--mainFontColor);
    width: 90%;
    margin: 0 auto;
    overflow: hidden;
    font-family: var(--mainFontFamily2);
}

.Assets_AssetTitle__1hq0y {
    font-size: 12px; /* changed 2022-02-21 */
    font-weight: 600;
    cursor: pointer;
}

.Assets_Assets__2rVeL {
    background-color: var(--mainBgColor);
    color: var(--mainFontColor);
    border-radius: 10px;
}

.Assets_Assets__2rVeL:hover {
    cursor: pointer;
}

.Assets_Asset__3N6hI {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: var(--mainFontColor);
    padding-bottom: 4px;
    padding-top: 4px;
    max-height: 100px;
    min-height: 80px;
}

.Assets_AssetTitle__1hq0y {
    padding-bottom: 0px;
    overflow: hidden;
    align-items: flex-start !important;
    max-height: 34px;
}

.Assets_AssetInfo__1ax21 {
    font-size: 10px; /* changed 2022-02-21 */
    color: var(--mainFontColor);
    padding-bottom: 0px;
}

.Assets_AssetDuration__1btbD {
    font-size: 10px; /* changed 2022-02-21 */
    margin-top: 10px;
}

.Assets_AssetInfo__1ax21,
.Assets_AssetTitle__1hq0y,
.Assets_AssetDuration__1btbD {
    width: 90%;
    margin: 5px 0;
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.Assets_AssetTitle__1hq0y:hover {
    text-decoration: underline;
    color: var(--mainFontColor);
}

.Assets_icon__2tHY8 {
    font-size: 13px;
}

.Assets_event__3G-Xk {
    width: 80%;
    margin: 0 auto;
    padding-top: 30px;
}

.Assets_event__3G-Xk h2 {
    color: var(--mainFontColor);
    padding-bottom: 0;
}

.Assets_iframe__2G3XZ {
    /* height: 80vh; */
    border: none;
    margin: 10px 0;
}

.Assets_ViewedSectionSecondary__2videos__3PjrN {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(350px, 100%), 300px));
    align-items: flex-start;
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
    grid-row-gap: 20px;
    row-gap: 20px;
}

.Assets_ViewedSectionSecondary__3videos__EFXPl {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%), 350px));
    align-items: flex-start;
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
    grid-row-gap: 20px;
    row-gap: 20px;
    justify-content: center;
}

.Assets_NoDataAvailable__2LNj1 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
}

.Assets_NoDataAvailable__2LNj1 h3 {
    font-size: 1.3rem;
}

.Assets_NoDataAvailable__2LNj1 img {
    width: 200px;
}

.Assets_modalItem__-HoeS {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.Assets_modalItem_button__1p-mp {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    margin-bottom: 20px;
}

.Assets_modalItem_button__1p-mp button {
}

.Assets_modalItem_button_confirm__1NW0i {
    background-color: var(--mainYellow);
    color: var(--mainBlack);
}

.Assets_modalItem_button_confirm__1NW0i:hover {
    background-color: var(--clr-primary-4);
    color: var(--mainWhite);
}

.Assets_modalItem_button_cancel__2IDJ3:hover {
    background-color: var(--mainHover);
    color: var(--mainWhite);
}

.Assets_Assets_main_info_filter_addFilter__107Hr,
.Assets_Assets_main_info_filter_removeFilter__1frwb {
    display: inline-block;
    background: #000;
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin: 5px;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    font-size: 15px;
    font-family: inherit;
    color: var(--mainModalFontColor);
    background-color: var(--mainModalBgColor);
}

.Assets_Assets_main_info_filter_addFilter__107Hr:hover .Assets_filterIcon__1Qwsb {
    background-color: var(--mainModalBgColor);
    color: var(--mainModalFontColor);
}

.Assets_Assets_main_info_filter_addFilter__107Hr:hover {
    background-color: var(--mainModalBgColor);
    color: var(--mainModalFontColor);
}

.Assets_Assets_main_info_filter_removeFilter__1frwb:hover .Assets_removeIcon__2_xrC {
    background-color: var(--mainModalBgColor);
    color: var(--mainModalFontColor);
}

.Assets_Assets_main_info_filter_removeFilter__1frwb:hover {
    background-color: var(--mainModalBgColor);
    color: var(--mainModalFontColor);
}

/* filter icon */
.Assets_filterIcon__1Qwsb {
    color: var(--mainModalFontColor);
}

.Assets_filterIcon__1Qwsb:hover {
    color: var(--mainModalFontColor);
    cursor: pointer;
}

.Assets_removeIcon__2_xrC {
    color: red;
}

.Assets_Assets_main_info_button__3Wv7Z {
    display: inline-block;
    position: relative;
}

.Assets_Assets_main_remove_button__1tNgH {
}

.Assets_Assets_main_info_header__2l4xz {
    display: block;
}

.Assets_Assets_main_info_header__2l4xz h2 {
    white-space: nowrap;
}

.Assets_Assets_main_info_search__3TZ5Y {
    display: block;
    position: relative;
    margin-right: 20px;
}

.Assets_Assets_main_info_search_box__Oj3wY {
    display: block;
    text-align: right;
    padding: 5px 10px;
}

.Assets_Assets_main_info_search_box__Oj3wY input {
    font-size: 16px;
}

.Assets_Assets_main_info_date_range__3V_jF {
    font-family: var(--mainFontFamily2);
}

.Assets_Assets_main_info_search_date__2HDql {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.Assets_Assets_search_icon__3WlMg {
    font-size: 16px;
    display: inline-block;
    margin-left: 5px;
}

@media (max-width: 1050px) {
    .Assets_container_2videos__VvKOh {
        width: 100%;
    }
}

@media (max-width: 850px) {
    .Assets_ViewedSectionSecondary__2videos__3PjrN {
        grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%), 300px));
    }

    .Assets_container_2videos__VvKOh {
        width: 100%;
    }
}

@media (max-width: 650px) {
    .Assets_container_2videos__VvKOh {
        width: 80%;
        margin: 0 auto;
    }
}

* {
    box-sizing: border-box;
}

.LatestAndMostPopularAssets_row__3juPR {
    margin-left: -5px;
    margin-right: -5px;
}

.LatestAndMostPopularAssets_column__3tx6r {
    float: left;
    width: 50%;
    padding: 5px;
}

/* Clearfix (clear floats) */
.LatestAndMostPopularAssets_row__3juPR::after {
    content: "";
    clear: both;
    display: table;
}

.LatestAndMostPopularAssets_resultItemTitle__RTFrU {
    font-size: 12px; /* changed 2022-02-21 */
    /*font-weight: 600;*/
    color: #394855;
    margin-bottom: 2%;
    height: 140px; /* controls height of boxes in table */
}

.LatestAndMostPopularAssets_assetImage__3oX2P {
    cursor: pointer;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    /* color: "#394855"; */
    background-color: white;

    /* border: 1px solid #ddd; */
}

th,
td {
    text-align: left;
    padding: 16px;
}

tr {
    border-bottom: 1px solid #ddd;
}

/* tr:nth-child(even) {
    background-color: #f2f2f2;
} */

/* BORROWED FROM Assets.module.css */

.LatestAndMostPopularAssets_Assets_loader__2kQ_H {
    background-color: #eceff1;
    width: 100%;
    height: 100%;
}

.LatestAndMostPopularAssets_Assets_loader__2kQ_H div {
    margin: 200px auto;
}

.LatestAndMostPopularAssets_Assets_loader__2kQ_H div svg {
    background: #eceff1;
}

.LatestAndMostPopularAssets_Assets_homeLink__3BIKJ {
    width: 90%;
    margin: auto;
}

.LatestAndMostPopularAssets_Assets_homeLink__3BIKJ a {
    float: right;
}

.LatestAndMostPopularAssets_Assets_modal__2p5oF {
    /* box-shadow: 0 1px 4px rgb(0 0 0 / 20%); */
    border-radius: 6px;
    background-color: white;
    padding: 1rem;
    text-align: center;
    width: 40rem;
    left: calc(50% - 20rem);
    height: 28rem;
    z-index: 10;
    position: fixed;
    top: 20vh;
}

.LatestAndMostPopularAssets_Assets_modal_title__3PDHk {
    color: white;
    text-align: center;
}

.LatestAndMostPopularAssets_Assets_modal__2p5oF > h2 {
    padding: 20px;
    margin: 0;
    /* border-bottom: 1px solid #555; */
}

.LatestAndMostPopularAssets_Assets_overlay__3zBp- {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 100;
}

.LatestAndMostPopularAssets_container_2videos__3czPJ {
    color: var(--mainFontColor);
    width: 70%;
    margin: 0 auto;
    overflow: hidden;
    font-family: var(--mainFontFamily2);
}

.LatestAndMostPopularAssets_Assets_dashboard_title__317_1 {
    width: 100%;
}

.LatestAndMostPopularAssets_Assets_main__1OXc8 {
    margin: 30px auto;
}

.LatestAndMostPopularAssets_Assets_main__1OXc8 h2 {
    color: var(--mainFontColor);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    margin-bottom: 0;
    font-size: 1.5rem;
}

.LatestAndMostPopularAssets_Assets_main_info__1vnAQ {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 30px;
    grid-gap: 10px;
    gap: 10px;
    position: relative;
    width: 100%;
}

.LatestAndMostPopularAssets_Assets_main_info__1vnAQ button {
    padding: 10px 15px !important;
}

.LatestAndMostPopularAssets_Assets_main_info__1vnAQ a {
    height: 100%;
    border: none;
    color: var(--mainFontColor);
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    position: absolute;
    right: 10%;
}

.LatestAndMostPopularAssets_Assets_main_info__1vnAQ a:hover {
    cursor: pointer;
    color: var(--mainFontColor);
    transition: all ease 0.4s;
}

.LatestAndMostPopularAssets_Assets_main_info__1vnAQ h2 {
    display: inline-block;
    white-space: nowrap;
}

.LatestAndMostPopularAssets_Assets_main_info__1vnAQ h2,
.LatestAndMostPopularAssets_Assets_main_info__1vnAQ button {
    padding: 10px 30px;
}

.LatestAndMostPopularAssets_Assets_main__1OXc8 h2::after {
    display: none;
}

.LatestAndMostPopularAssets_container_3videos__3pZch {
    color: var(--mainFontColor);
    width: 90%;
    margin: 0 auto;
    overflow: hidden;
    font-family: var(--mainFontFamily2);
}

.LatestAndMostPopularAssets_AssetTitle__3iCFh {
    font-size: 12px; /* changed 2022-02-21 */
    font-weight: 600;
}

.LatestAndMostPopularAssets_Assets__2YYM9 {
    background-color: var(--mainBgColor);
    color: var(--mainFontColor);
    border-radius: 10px;
}

.LatestAndMostPopularAssets_Assets__2YYM9:hover {
    cursor: pointer;
}

.LatestAndMostPopularAssets_Asset__YPUAs {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: var(--mainFontColor);
    padding-bottom: 5px;
    padding-top: 5px;
    max-height: 98px;
    min-height: 70px;
}

.LatestAndMostPopularAssets_AssetTitle__3iCFh {
    padding-bottom: 0px;
    overflow: hidden;
    align-items: flex-start !important;
    max-height: 34px;
}

.LatestAndMostPopularAssets_AssetInfo__125Hl {
    font-size: 10px; /* changed 2022-02-21 */
    color: var(--mainFontColor);
    padding-bottom: 0px;
}

.LatestAndMostPopularAssets_AssetDuration__3c9Og {
    font-size: 10px; /* changed 2022-02-21 */
    margin-top: 10px;
}

.LatestAndMostPopularAssets_AssetInfo__125Hl,
.LatestAndMostPopularAssets_AssetTitle__3iCFh,
.LatestAndMostPopularAssets_AssetDuration__3c9Og {
    width: 90%;
    margin: 5px 0;
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.LatestAndMostPopularAssets_AssetTitle__3iCFh:hover {
    text-decoration: underline;
    color: var(--mainFontColor);
}

.LatestAndMostPopularAssets_icon__15CMM {
    font-size: 13px;
}

.LatestAndMostPopularAssets_event__2heYS {
    width: 80%;
    margin: 0 auto;
    padding-top: 30px;
}

.LatestAndMostPopularAssets_event__2heYS h2 {
    color: var(--mainFontColor);
    padding-bottom: 0;
}

.LatestAndMostPopularAssets_iframe__1Ceej {
    /* height: 80vh; */
    border: none;
    margin: 10px 0;
}

.LatestAndMostPopularAssets_ViewedSectionSecondary__2videos__3Z7At {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(350px, 100%), 300px));
    align-items: flex-start;
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
    grid-row-gap: 20px;
    row-gap: 20px;
}

.LatestAndMostPopularAssets_ViewedSectionSecondary__3videos__3K288 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%), 350px));
    align-items: flex-start;
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
    grid-row-gap: 20px;
    row-gap: 20px;
    justify-content: center;
}

.LatestAndMostPopularAssets_NoDataAvailable__3iqth {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
}

.LatestAndMostPopularAssets_NoDataAvailable__3iqth h3 {
    font-size: 1.3rem;
}

.LatestAndMostPopularAssets_NoDataAvailable__3iqth img {
    width: 200px;
}

.LatestAndMostPopularAssets_modalItem__1N_po {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.LatestAndMostPopularAssets_modalItem_button__23YWj {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    margin-bottom: 20px;
}

.LatestAndMostPopularAssets_modalItem_button__23YWj button {
}

.LatestAndMostPopularAssets_modalItem_button_confirm__3AdG_ {
    background-color: var(--mainYellow);
    color: var(--mainBlack);
}

.LatestAndMostPopularAssets_modalItem_button_confirm__3AdG_:hover {
    background-color: var(--clr-primary-4);
    color: var(--mainWhite);
}

.LatestAndMostPopularAssets_modalItem_button_cancel__1ruAs:hover {
    background-color: var(--mainHover);
    color: var(--mainWhite);
}

.LatestAndMostPopularAssets_Assets_main_info_filter_addFilter__3PLSq,
.LatestAndMostPopularAssets_Assets_main_info_filter_removeFilter__3x5CM {
    display: inline-block;
    background: #000;
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin: 5px;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    font-size: 15px;
    font-family: inherit;
    color: var(--mainModalFontColor);
    background-color: var(--mainModalBgColor);
}

.LatestAndMostPopularAssets_Assets_main_info_filter_addFilter__3PLSq:hover .LatestAndMostPopularAssets_filterIcon__12bJ6 {
    background-color: var(--mainModalBgColor);
    color: var(--mainModalFontColor);
}

.LatestAndMostPopularAssets_Assets_main_info_filter_addFilter__3PLSq:hover {
    background-color: var(--mainModalBgColor);
    color: var(--mainModalFontColor);
}

.LatestAndMostPopularAssets_Assets_main_info_filter_removeFilter__3x5CM:hover .LatestAndMostPopularAssets_removeIcon__g1wUP {
    background-color: var(--mainModalBgColor);
    color: var(--mainModalFontColor);
}

.LatestAndMostPopularAssets_Assets_main_info_filter_removeFilter__3x5CM:hover {
    background-color: var(--mainModalBgColor);
    color: var(--mainModalFontColor);
}

/* filter icon */
.LatestAndMostPopularAssets_filterIcon__12bJ6 {
    color: var(--mainModalFontColor);
}

.LatestAndMostPopularAssets_filterIcon__12bJ6:hover {
    color: var(--mainModalFontColor);
    cursor: pointer;
}

.LatestAndMostPopularAssets_removeIcon__g1wUP {
    color: red;
}

.LatestAndMostPopularAssets_Assets_main_info_button__2fSDy {
    display: inline-block;
    position: relative;
}

.LatestAndMostPopularAssets_Assets_main_remove_button__3DTla {
}

.LatestAndMostPopularAssets_Assets_main_info_header__1kjr5 {
    display: block;
}

.LatestAndMostPopularAssets_Assets_main_info_header__1kjr5 h2 {
    white-space: nowrap;
}

.LatestAndMostPopularAssets_Assets_main_info_search__3HUru {
    display: block;
    position: relative;
    margin-right: 20px;
}

.LatestAndMostPopularAssets_Assets_main_info_search_box__20IMS {
    display: block;
    text-align: right;
    padding: 5px 10px;
}

.LatestAndMostPopularAssets_Assets_main_info_search_box__20IMS input {
    font-size: 16px;
}

.LatestAndMostPopularAssets_Assets_main_info_date_range__JCvA6 {
    font-family: var(--mainFontFamily2);
}

.LatestAndMostPopularAssets_Assets_main_info_search_date__2wMVz {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.LatestAndMostPopularAssets_Assets_search_icon__3dvP9 {
    font-size: 16px;
    display: inline-block;
    margin-left: 5px;
}

@media (max-width: 1050px) {
    .LatestAndMostPopularAssets_container_2videos__3czPJ {
        width: 100%;
    }
}

@media (max-width: 850px) {
    .LatestAndMostPopularAssets_ViewedSectionSecondary__2videos__3Z7At {
        grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%), 300px));
    }

    .LatestAndMostPopularAssets_container_2videos__3czPJ {
        width: 100%;
    }
}

@media (max-width: 650px) {
    .LatestAndMostPopularAssets_container_2videos__3czPJ {
        width: 80%;
        margin: 0 auto;
    }
}

.VODAnalyticsDashboard_actions__2T3Wi {
    text-align: right;
}

.VODAnalyticsDashboard_select__3UulS {
    float: right;
    padding: 8px 0px 0px 8px;
    cursor: pointer;
    font-size: 14px;
    font-family: inherit;
    text-align: left;
    width: 12rem;
}

/* was .content-wrapper */
.VODAnalyticsDashboard_container__3gWZF {
    width: 100%;
    /* width: 960px; */
    /* overflow-x: hidden;*/
    /*overflow-y: auto; */

    /* max-width: 500px;
    margin: 30px auto;
    overflow: auto;
    min-height: 300px;
    border: 1px solid steelblue;
    padding: 30px;
    border-radius: 5px; */
}

.VODAnalyticsDashboard_view__RYEks {
    width: 95%;
    margin: 0 auto;
    /*height: 100%; */
    /* overflow: auto; */
}

.VODAnalyticsDashboard_overviewWrapper1__3zt0H {
    display: grid;
    /* grid-template-columns: 20% 20% 20% 20% 20%; */
    grid-template-columns: 25% 25% 25% 25%;
    /* grid-template-columns: 33% 33% 33%; */
    /* grid-template-columns: 50% 50%; */
    background-color: white;
    /* box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); */
    padding: 1%;
    font-family: "Raleway", "sans-serif";
    border-radius: 0.5rem;
}

.VODAnalyticsDashboard_overviewWrapper15__2uFwQ {
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    background-color: white;
    /* box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); */
    padding: 0.5%;
    font-family: "Raleway", "sans-serif";
    border-radius: 0.5rem;
    margin-bottom: 1rem;
}

.VODAnalyticsDashboard_overviewWrapper16__2KTIX {
    display: grid;
    grid-template-columns: 16.6% 16.6% 16.6% 16.6% 16.6% 16.6%;
    background-color: white;
    /* box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); */
    padding: 0.5%;
    font-family: "Raleway", "sans-serif";
    border-radius: 0.5rem;
    margin-bottom: 1rem;
}

.VODAnalyticsDashboard_overviewWrapper2__K5OLj {
    font-family: "Raleway", "sans-serif";
    display: inline-flex;
    flex-wrap: wrap;
    width: 100%;
    font-size: 18px;
    /*gap: 20px;*/
}

.VODAnalyticsDashboard_overviewWrapper2__K5OLj > * + * {
    margin-left: 20px;
}

.VODAnalyticsDashboard_overviewWrapper3__1v9Uo {
    /* display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%; */
    background-color: white;
    /* box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); */
    /* padding: 2%; */
    /* padding-top: 5px; */
    /* padding-top: 5px; */
    /* padding-top: 5px; */
    /* padding-top: 5px; */
    font-family: "Raleway", "sans-serif";

    border-radius: 0.5rem;
}

.VODAnalyticsDashboard_resultItem3__30RFT {
    width: 90%;

    background: white;
    /* box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); */
    padding: 2rem 2rem 2rem 2rem;

    /* margin-top: 20px; */
    margin: 0 1% 1% 1%;
}

/* search input */
.VODAnalyticsDashboard_input__XNZFf {
    /* display: inline-block; */
    /* background: #000; */
    /* color: #fff; */
    /* border: none; */
    padding: 8px 16px;
    /* margin: 5px; */
    margin-bottom: 20px;
    margin-left: 10px;
    margin-top: 10px;
    margin-right: 10px;
    border-radius: 5px;
    /* cursor: pointer; */
    text-decoration: none;
    font-size: 12px; /* changed 2022-02-21 */
    font-family: inherit;
}

svg {
    background: white;
    /* for stuff such as the axis to show below the graph */
    overflow: visible;
    display: block;
    width: 100%;
}

/* #root {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    height: 100vh;
    padding: 0 50px;
} */

.VODAnalyticsDashboard_svgButton__8Hf_6 {
    width: 100px;
    border: 1px solid black;
    margin: 0 auto 5px;
}

.Legend_legendContainer__26jgN {
    display: flex;
    justify-content: center;
}
.Legend_checkbox__13x3a {
    margin: 10px;
}

.HomeLink_homeNav__3puxZ {
    background: white;
    color: black;
}

.HomeLink_homeNavLink__2lGHd {
    list-style: none;
    float: right;
}

.HomeLink_homeNavLink__2lGHd > a {
    color: black;
    font-weight: bold;
    text-decoration: none;
}

.AssetStatisticsHeading_periodName__2vuX2 {
    /*font-size: 12px;*/ /* changed 2022-02-21 */
    /*font-weight: 600;*/
    /*color: #394855;*/
    margin-top: 2px; /* changed 2022-02-21 */
    margin-bottom: 2px; /* changed 2022-02-21 */
}

.AssetStatisticsHeading_dateRange__3qWSw {
    font-size: 10px;
    margin-top: 2px; /* changed 2022-02-21 */
    margin-bottom: 5px; /* changed 2022-02-21 */
}

.ValueCard2_resultItem2__2HyxO {
    display: flex;
    flex-grow: 1;
    width: 22%;
    /* height: 12rem;
    min-width: 280px;
    min-height: 200px; */
    flex-direction: column;
    align-items: center;
    background: white;
    /* box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); */
    padding-top: 5px;
    border-radius: 0.5rem;
    vertical-align: middle;
    margin-top: 20px;
}

.ValueCard2_resultItemTitle__1I3OY {
    font-size: 12px; /* changed 2022-02-21 */
    /*font-weight: 600;*/
    color: #394855;
    margin-bottom: 2%;
}

.ValueCard2_amountNumber__1xcvk {
    font-family: "Raleway", sans-serif;
    font-size: 30px; /* changed 2022-02-21 */
    font-weight: 800;
}

.VODAnalyticsAssetOverview_actions__3TJq3 {
    text-align: right;
}

/* was .content-wrapper */
.VODAnalyticsAssetOverview_container__1hD1r {
    width: 100%;
    /* width: 960px; */
    /* overflow-x: hidden;*/
    /*overflow-y: auto; */

    /* max-width: 500px;
    margin: 30px auto;
    overflow: auto;
    min-height: 300px;
    border: 1px solid steelblue;
    padding: 30px;
    border-radius: 5px; */
}

.VODAnalyticsAssetOverview_view__189gS {
    width: 95%;
    margin: 0 auto;
    /*height: 100%; */
    /* overflow: auto; */
}

.VODAnalyticsAssetOverview_overviewWrapper15__2ntBt {
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    background-color: white;
    /* box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); */
    padding: 0.5%;
    font-family: "Raleway", "sans-serif";
    border-radius: 0.5rem;
    /* margin-bottom: 0.5rem; */
}

.VODAnalyticsAssetOverview_overviewWrapper14__2JRP7 {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    background-color: white;
    /* box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); */
    padding: 0.5%;
    font-family: "Raleway", "sans-serif";
    border-radius: 0.5rem;
    /* margin-bottom: 0.5rem; */
}

.VODAnalyticsAssetOverview_overviewWrapper2__3R3N5 {
    font-family: "Raleway", "sans-serif";
    display: inline-flex;
    flex-wrap: wrap;
    width: 100%;
    font-size: 18px;
    /*gap: 20px;*/
}

.VODAnalyticsAssetOverview_overviewWrapper2__3R3N5 > * + * {
    margin-left: 20px;
}

.VODAnalyticsAssetOverview_overviewWrapper3__3iV6r {
    /* display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%; */
    background-color: white;
    /* box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); */
    /* padding: 2%; */
    /* padding-top: 5px; */
    /* padding-top: 5px; */
    /* padding-top: 5px; */
    /* padding-top: 5px; */
    font-family: "Raleway", "sans-serif";

    border-radius: 0.5rem;
}

.VODAnalyticsAssetOverview_resultItem3__3-ZM- {
    width: 90%;

    background: white;
    /* box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); */
    padding: 2rem 2rem 2rem 2rem;

    /* margin-top: 20px; */
    margin: 0 1% 1% 1%;
}

svg {
    background: white;
    /* for stuff such as the axis to show below the graph */
    overflow: visible;
    display: block;
    width: 100%;
}

/* #root {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    height: 100vh;
    padding: 0 50px;
} */

.VODAnalyticsAssetOverview_svgButton__dtlun {
    width: 100px;
    border: 1px solid black;
    margin: 0 auto 5px;
}

* {
    box-sizing: border-box;
}

.AssetsTable_row__27LO6 {
    margin-left: -5px;
    margin-right: -5px;
}

.AssetsTable_column__1F35M {
    float: left;
    width: 90%;
    padding: 5px;
}

/* Clearfix (clear floats) */
.AssetsTable_row__27LO6::after {
    content: "";
    clear: both;
    display: table;
}

.AssetsTable_resultItemTitle__2i7Aq {
    font-size: 12px; /* changed 2022-02-21 */
    /*font-weight: 600;*/
    color: #394855;
    margin-bottom: 2%;
}

.AssetsTable_assetImage__2VRw6 {
    cursor: pointer;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    /* color: "#394855"; */
    background-color: white;

    /* border: 1px solid #ddd; */
}

th,
td {
    text-align: left;
    padding: 16px;
}

tr {
    border-bottom: 1px solid #ddd;
}

/* tr:nth-child(even) {
    background-color: #f2f2f2;
} */

/* BORROWED FROM Assets.module.css */

.AssetsTable_Assets_loader__39bKu {
    background-color: #eceff1;
    width: 100%;
    height: 100%;
}

.AssetsTable_Assets_loader__39bKu div {
    margin: 200px auto;
}

.AssetsTable_Assets_loader__39bKu div svg {
    background: #eceff1;
}

.AssetsTable_Assets_homeLink__2Kz7I {
    width: 90%;
    margin: auto;
}

.AssetsTable_Assets_homeLink__2Kz7I a {
    float: right;
}

.AssetsTable_Assets_modal__1FgPc {
    /* box-shadow: 0 1px 4px rgb(0 0 0 / 20%); */
    border-radius: 6px;
    background-color: white;
    padding: 1rem;
    text-align: center;
    width: 40rem;
    left: calc(50% - 20rem);
    height: 28rem;
    z-index: 10;
    position: fixed;
    top: 20vh;
}

.AssetsTable_Assets_modal_title__2skgC {
    color: white;
    text-align: center;
}

.AssetsTable_Assets_modal__1FgPc > h2 {
    padding: 20px;
    margin: 0;
    /* border-bottom: 1px solid #555; */
}

.AssetsTable_Assets_overlay__1h4VL {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 100;
}

.AssetsTable_container_2videos__v5bjd {
    color: var(--mainFontColor);
    width: 70%;
    margin: 0 auto;
    overflow: hidden;
    font-family: var(--mainFontFamily2);
}

.AssetsTable_Assets_dashboard_title__uMcQy {
    width: 100%;
}

.AssetsTable_Assets_main__UfdYe {
    margin: 30px auto;
}

.AssetsTable_Assets_main__UfdYe h2 {
    color: var(--mainFontColor);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    margin-bottom: 0;
    font-size: 1.5rem;
}

.AssetsTable_Assets_main_info__1lD8j {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 30px;
    grid-gap: 10px;
    gap: 10px;
    position: relative;
    width: 100%;
}

.AssetsTable_Assets_main_info__1lD8j button {
    padding: 10px 15px !important;
}

.AssetsTable_Assets_main_info__1lD8j a {
    height: 100%;
    border: none;
    color: var(--mainFontColor);
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    position: absolute;
    right: 10%;
}

.AssetsTable_Assets_main_info__1lD8j a:hover {
    cursor: pointer;
    color: var(--mainFontColor);
    transition: all ease 0.4s;
}

.AssetsTable_Assets_main_info__1lD8j h2 {
    display: inline-block;
    white-space: nowrap;
}

.AssetsTable_Assets_main_info__1lD8j h2,
.AssetsTable_Assets_main_info__1lD8j button {
    padding: 10px 30px;
}

.AssetsTable_Assets_main__UfdYe h2::after {
    display: none;
}

.AssetsTable_container_3videos__wP6Th {
    color: var(--mainFontColor);
    width: 90%;
    margin: 0 auto;
    overflow: hidden;
    font-family: var(--mainFontFamily2);
}

.AssetsTable_AssetTitle__RolR8 {
    font-size: 12px; /* changed 2022-02-21 */
    font-weight: 600;
}

.AssetsTable_Assets__2Usb4 {
    background-color: var(--mainBgColor);
    color: var(--mainFontColor);
    border-radius: 10px;
}

.AssetsTable_Assets__2Usb4:hover {
    cursor: pointer;
}

.AssetsTable_Asset__2pDnZ {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: var(--mainFontColor);
    padding-bottom: 5px;
    padding-top: 5px;
    max-height: 98px;
    min-height: 70px;
}

.AssetsTable_AssetTitle__RolR8 {
    padding-bottom: 0px;
    overflow: hidden;
    align-items: flex-start !important;
    max-height: 34px;
}

.AssetsTable_AssetInfo__10-XL {
    font-size: 10px; /* changed 2022-02-21 */
    color: var(--mainFontColor);
    padding-bottom: 0px;
}

.AssetsTable_AssetDuration__1FAIU {
    font-size: 10px; /* changed 2022-02-21 */
    margin-top: 10px;
}

.AssetsTable_AssetInfo__10-XL,
.AssetsTable_AssetTitle__RolR8,
.AssetsTable_AssetDuration__1FAIU {
    width: 90%;
    margin: 5px 0;
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.AssetsTable_AssetTitle__RolR8:hover {
    text-decoration: underline;
    color: var(--mainFontColor);
}

.AssetsTable_icon__1heKj {
    font-size: 13px;
}

.AssetsTable_event__1Fzxe {
    width: 80%;
    margin: 0 auto;
    padding-top: 30px;
}

.AssetsTable_event__1Fzxe h2 {
    color: var(--mainFontColor);
    padding-bottom: 0;
}

.AssetsTable_iframe__Q0yj9 {
    /* height: 80vh; */
    border: none;
    margin: 10px 0;
}

.AssetsTable_ViewedSectionSecondary__2videos__1kYui {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(350px, 100%), 300px));
    align-items: flex-start;
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
    grid-row-gap: 20px;
    row-gap: 20px;
}

.AssetsTable_ViewedSectionSecondary__3videos__ic7gS {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%), 350px));
    align-items: flex-start;
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
    grid-row-gap: 20px;
    row-gap: 20px;
    justify-content: center;
}

.AssetsTable_NoDataAvailable__o8EXS {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
}

.AssetsTable_NoDataAvailable__o8EXS h3 {
    font-size: 1.3rem;
}

.AssetsTable_NoDataAvailable__o8EXS img {
    width: 200px;
}

.AssetsTable_modalItem__3h_6f {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.AssetsTable_modalItem_button__1aahD {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    margin-bottom: 20px;
}

.AssetsTable_modalItem_button__1aahD button {
}

.AssetsTable_modalItem_button_confirm__xMjxI {
    background-color: var(--mainYellow);
    color: var(--mainBlack);
}

.AssetsTable_modalItem_button_confirm__xMjxI:hover {
    background-color: var(--clr-primary-4);
    color: var(--mainWhite);
}

.AssetsTable_modalItem_button_cancel__3Ltdg:hover {
    background-color: var(--mainHover);
    color: var(--mainWhite);
}

.AssetsTable_Assets_main_info_filter_addFilter__3wd7l,
.AssetsTable_Assets_main_info_filter_removeFilter__e3eOO {
    display: inline-block;
    background: #000;
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin: 5px;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    font-size: 15px;
    font-family: inherit;
    color: var(--mainModalFontColor);
    background-color: var(--mainModalBgColor);
}

.AssetsTable_Assets_main_info_filter_addFilter__3wd7l:hover .AssetsTable_filterIcon__3rQ_h {
    background-color: var(--mainModalBgColor);
    color: var(--mainModalFontColor);
}

.AssetsTable_Assets_main_info_filter_addFilter__3wd7l:hover {
    background-color: var(--mainModalBgColor);
    color: var(--mainModalFontColor);
}

.AssetsTable_Assets_main_info_filter_removeFilter__e3eOO:hover .AssetsTable_removeIcon__1kYB4 {
    background-color: var(--mainModalBgColor);
    color: var(--mainModalFontColor);
}

.AssetsTable_Assets_main_info_filter_removeFilter__e3eOO:hover {
    background-color: var(--mainModalBgColor);
    color: var(--mainModalFontColor);
}

/* filter icon */
.AssetsTable_filterIcon__3rQ_h {
    color: var(--mainModalFontColor);
}

.AssetsTable_filterIcon__3rQ_h:hover {
    color: var(--mainModalFontColor);
    cursor: pointer;
}

.AssetsTable_removeIcon__1kYB4 {
    color: red;
}

.AssetsTable_Assets_main_info_button__3HJ3v {
    display: inline-block;
    position: relative;
}

.AssetsTable_Assets_main_remove_button__1SojV {
}

.AssetsTable_Assets_main_info_header__2PhT_ {
    display: block;
}

.AssetsTable_Assets_main_info_header__2PhT_ h2 {
    white-space: nowrap;
}

.AssetsTable_Assets_main_info_search__2fcWs {
    display: block;
    position: relative;
    margin-right: 20px;
}

.AssetsTable_Assets_main_info_search_box__1YMd0 {
    display: block;
    text-align: right;
    padding: 5px 10px;
}

.AssetsTable_Assets_main_info_search_box__1YMd0 input {
    font-size: 16px;
}

.AssetsTable_Assets_main_info_date_range__Aa_TO {
    font-family: var(--mainFontFamily2);
}

.AssetsTable_Assets_main_info_search_date__1Urfr {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.AssetsTable_Assets_search_icon__2a420 {
    font-size: 16px;
    display: inline-block;
    margin-left: 5px;
}

@media (max-width: 1050px) {
    .AssetsTable_container_2videos__v5bjd {
        width: 100%;
    }
}

@media (max-width: 850px) {
    .AssetsTable_ViewedSectionSecondary__2videos__1kYui {
        grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%), 300px));
    }

    .AssetsTable_container_2videos__v5bjd {
        width: 100%;
    }
}

@media (max-width: 650px) {
    .AssetsTable_container_2videos__v5bjd {
        width: 80%;
        margin: 0 auto;
    }
}

.VODAnalyticsSearch_actions__1C32Q {
    text-align: right;
}

/* was .content-wrapper */
.VODAnalyticsSearch_container__1ewK0 {
    width: 100%;
    /* width: 960px; */
    /* overflow-x: hidden;*/
    /*overflow-y: auto; */

    /* max-width: 500px;
    margin: 30px auto;
    overflow: auto;
    min-height: 300px;
    border: 1px solid steelblue;
    padding: 30px;
    border-radius: 5px; */
}

.VODAnalyticsSearch_view__fsdUj {
    width: 95%;
    margin: 0 auto;
    /*height: 100%; */
    /* overflow: auto; */
}

.VODAnalyticsSearch_Assets_loader__8l_hH {
    background-color: #eceff1;
    width: 100%;
    height: 100%;
}

.VODAnalyticsSearch_Assets_loader__8l_hH div {
    margin: 200px auto;
}

.VODAnalyticsSearch_Assets_loader__8l_hH div svg {
    background: #eceff1;
}

